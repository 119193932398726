import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map }        from 'rxjs/operators';

// Const, Classes, Interfaces
import { User, UserInterface, OrganizationInterface } from '../models';
//Services
import { AbstractGraphQLService }                     from '../../core/graphql/services/abstract-graphql.service';
import { NxGraphQLMutationOptions }                   from 'src/app/core/graphql/nx-graphql.interfaces';

export interface UserQueryResponse {
  userList?: UserInterface[];
  user?    : UserInterface;
  profile? :  UserInterface;
}

@Injectable({
  providedIn: 'root'
})
export class UserService extends AbstractGraphQLService<UserInterface, UserQueryResponse> {

  /**
   * 
   * @param id 
   * @param retFields 
   * @param pollInterval 
   */
  profile(retFields?: string[], pollInterval?: number): Observable<UserInterface> {

    let options = { retFields, pollInterval };

    return this.apollo.watchQuery<UserQueryResponse>(this.getQueryOptions(this.queries.profile, options)).valueChanges.pipe(
      map(({ data }) => this.manageResult(data, 'profile'))
    );
  }

  /**
   * Alternative remove method to make only logical deletion
   * 
   * @param item 
   */
  removeUser(item: User): void {

    let updateData = Object.assign({}, {_id: item._id, deleted: true, deletedAt:new Date(), active: false});
    this.save(updateData, null, { refetch: [{}] });
  }

  /**
   * 
   * @param user 
   * @param item 
   * @param retFields 
   */
  addRole(user: User, item: string, retFields?: string[]): void {

    let options: NxGraphQLMutationOptions = { variables: { id: user._id, item }, retFields };
    this.mutate(this.getMutationOptions(this.queries.addRole, options), (res) => this.message.showSuccess('Role added'));
  }

  /**
   * 
   * @param user 
   * @param item 
   * @param retFields 
   */
  removeRole(user: User, item: string, retFields?: string[]): void {

    let options: NxGraphQLMutationOptions = { variables: { id: user._id, item }, retFields };
    this.mutate(this.getMutationOptions(this.queries.removeRole, options), (res) => this.message.showSuccess('Role removed'));
  }

  /**
   * 
   * @param user 
   * @param item 
   * @param retFields 
   */
  addOrganization(user: User, item: OrganizationInterface, retFields?: string[]): void {

    let options: NxGraphQLMutationOptions = { variables: { id: user._id, item: this.getArrayCodedItem(item) }, retFields };
    this.mutate(this.getMutationOptions(this.queries.addOrganization, options), (res) => this.message.showSuccess('Organization added'));
  }

  /**
   * 
   * @param user 
   * @param item 
   * @param retFields 
   */
  removeOrganization(user: User, item: OrganizationInterface, retFields?: string[]): void {

    let options: NxGraphQLMutationOptions = { variables: { id: user._id, item: this.getArrayCodedItem(item) }, retFields };
    this.mutate(this.getMutationOptions(this.queries.removeOrganization, options), (res) => this.message.showSuccess('Organization removed'));
  }

  /**
   * 
   * @param id 
   * @param retFields 
   * @param pollInterval 
   */
  updatePassword(oldPassword: string, newPassword: string, username?: string, retFields?: string[]): void {

    let options: NxGraphQLMutationOptions = { variables: { oldPwd: oldPassword, newPwd: newPassword, username: username }, retFields };
    this.mutate(this.getMutationOptions(this.queries.updatePassword, options), (res) => this.message.showSuccess('Successfully updated'));
  }

 /**
  * 
  * @param id 
  * @param newPassword 
  * @param retFields 
  */
  updatePasswordByAdmin(id: string, newPassword: string, retFields?: string[]): void {

    let options: NxGraphQLMutationOptions = { variables: { id, newPwd: newPassword }, retFields };
    this.mutate(this.getMutationOptions(this.queries.updatePasswordByAdmin, options), (res) => this.message.showSuccess('Successfully updated'));
  }

  /**
   * 
   * @param id 
   * @param retFields 
   * @param pollInterval 
   */
  updatePasswordByToken(username: string, token: string, newPwd: string, callback?: Function): void {

    let options: NxGraphQLMutationOptions = { variables: { username, token, newPwd } };
    
    this.mutate(this.getMutationOptions(this.queries.updatePasswordByToken, options), (res) => {

      if (res.updatePasswordByToken) {
        callback && callback();
        this.message.showSuccess('Successfully updated');
        return;
      }

      this.message.showError('Update error, check token');
    });
  }

  /**
   * 
   * @param id 
   * @param retFields 
   * @param pollInterval 
   */
  sendResetToken(username: string): void {

    let options: NxGraphQLMutationOptions = { variables: { username } };
    
    this.mutate(this.getMutationOptions(this.queries.sendResetToken, options), (res) => this.message.showSuccess('Token successfully sent'));
  }

  /**
   * Called before insert or update
   */
  protected beforeSave(item: UserInterface): UserInterface {

    if (!item._id && item.organizations)
      item.organizations = item.organizations.map(org => { return this.getArrayCodedItem(org); });

    return item;
  }

  /**
   * 
   */
  protected setupParams(): void {

    this.name          = 'user';
    this.baseRetFields =  ['_id', 'active', 'email', 'createdAt', 'deleted', 'deletedAt', 'functs', 'organizations', 'username', 'updatedAt', 'profile { firstname, lastname }', 'roles'];
  }

  /**
   * 
   */
  protected setupQueries(): void {

    this.addQuery('profile', `profile`, null, this.baseRetFields);

    this.addMutation('addRole',               'addUserRole',            [{ param: 'id',       type: 'ID!'},      { param: 'item', type: 'String!' }],         this.baseRetFields);
    this.addMutation('removeRole',            'removeUserRole',         [{ param: 'id',       type: 'ID!'},      { param: 'item', type: 'String!' }],         this.baseRetFields);
    this.addMutation('addOrganization',       'addUserOrganization',    [{ param: 'id',       type: 'ID!'},      { param: 'item', type: 'ArrayCodedItem!' }], this.baseRetFields);
    this.addMutation('removeOrganization',    'removeUserOrganization', [{ param: 'id',       type: 'ID!'},      { param: 'item', type: 'ArrayCodedItem!' }], this.baseRetFields);
    this.addMutation('updatePassword',        'updatePassword',         [{ param: 'oldPwd',   type: 'String!' }, { param: 'newPwd', type: 'String!' }, { param: 'username', type: 'String'  }], this.baseRetFields);
    this.addMutation('updatePasswordByToken', 'updatePasswordByToken',  [{ param: 'username', type: 'String!'},  { param: 'token',  type: 'String!' }, { param: 'newPwd',   type: 'String!' }]);
    this.addMutation('updatePasswordByAdmin', 'updatePasswordByAdmin',  [{ param: 'id',       type: 'ID!' },     { param: 'newPwd', type: 'String!' }], this.baseRetFields);
    this.addMutation('sendResetToken',        'sendResetToken',         [{ param: 'username', type: 'String!'}]);
  }
}
