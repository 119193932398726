import { FormControl } from '@angular/forms';

export class NxValidation {

  matchField(field1: string, field2: string, error: any): any {

    return (form: FormControl) => {

      if (form.value[field1] !== form.value[field2])
        form.get([field2]).setErrors({ matchControlPasswordError: true });
      
      return null;
    };
  }

  notMatchField(field1: string, field2: string, error: any): any {

    return (form: FormControl) => {

      if (form.value[field1] == form.value[field2])
        form.get([field2]).setErrors({ oldPasswordMatchNewError: true });
      
      return null;
    };
  }
}